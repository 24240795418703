/**
 * 
 * @param {Blob} blob
 * @returns {Promise<string>}
 */
export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = function(e) {
      const { target: { result } } = e;
      resolve(result);
    };
    fileReader.onerror = function(e) {
      const { target: { error: { message } } } = e;
      reject(message);
    };
    fileReader.readAsDataURL(blob);
  });
}

/**
 * 
 * @param {string} base64str
 * @returns {Uint8Array}
 */
export function base64ToUint8Array(base64str) {
  const decoded = atob(base64str);
  const { length } = decoded;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = decoded.charCodeAt(i);
  }
  return bytes;
}
