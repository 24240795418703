import Axios from "axios";

class GoogleDrive {
  endpoint = 'https://www.googleapis.com/drive/v3/';

  /**
   * 
   * @param {string} accessToken 
   */
  constructor(accessToken) {
    this.accessToken = accessToken;
  }

  get headers() {
    return {
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  /**
   * 
   * @param {string} q 
   */
  listFiles(q) {
    return Axios.get(`${this.endpoint}files?q=${encodeURIComponent(q)}`, { headers: this.headers });
  }

  /**
   * 
   * @param {string} id 
   */
  getFile(id) {
    return Axios.get(`${this.endpoint}files/${id}?alt=media`, { headers: this.headers, responseType: 'blob' });
  }
}

export default GoogleDrive;
