import GoogleLogin from "react-google-login";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import background from "./../background.jpg";
import logo from "../cloud.png";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  background: {
    height: '100%',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '2em',
    paddingBottom: '30px',
    color: 'rgba(255, 255, 255, 1)',
  },
  logo: {
    paddingBottom: '10px',
  },
}));

function Login({ onLogin }) {
  const classes = useStyle();

  return (
    <div className={classes.background}>
      <div className={classes.column}>
        <div className={clsx(classes.row, classes.logo)}>
          <img src={logo} width="200" alt="logo" />
        </div>
        <div className={clsx(classes.row, classes.title)}>
          Safe in Cloud
        </div>
        <div className={classes.row}>
          <GoogleLogin
            clientId="536009887161-e7q6g726srds0kf4g5p6qncbck8crlp2.apps.googleusercontent.com"
            scope="profile email https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata.readonly"
            onSuccess={onLogin}
          />
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  onLogin: PropTypes.func
}

export default Login;
