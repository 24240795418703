import { Avatar, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useGoogleLogout } from "react-google-login";
import { DRIVE_TOKEN_COOKIE, USER_DETAIL_COOKIE } from "../model/consts";

function AccountMenu() {
  const [{ [USER_DETAIL_COOKIE]: userDetail },, removeCookie] = useCookies([USER_DETAIL_COOKIE, DRIVE_TOKEN_COOKIE]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { signOut } = useGoogleLogout({
    clientId: '536009887161-e7q6g726srds0kf4g5p6qncbck8crlp2.apps.googleusercontent.com',
  });

  if (!Boolean(userDetail)) {
    return null;
  }

  const { name, email, imageUrl } = userDetail;
  const isOpen = Boolean(anchorEl);

  const handleLogOut = function() {
    signOut();
    removeCookie(USER_DETAIL_COOKIE);
    removeCookie(DRIVE_TOKEN_COOKIE);
  };

  return (
    <>
      <IconButton color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Avatar alt={name} src={imageUrl} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem disabled>{email}</MenuItem>
        <MenuItem onClick={handleLogOut}>Log out</MenuItem>
      </Menu>
    </>
  );
}

export default AccountMenu;
