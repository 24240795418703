import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./app/store";
import Main from "./components/Main";

function App() {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <Provider store={store}>
          <Main />
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  );
}

export default App;
