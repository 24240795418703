import { List } from "@material-ui/core";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { base64ToUint8Array, blobToBase64 } from "../model/converts";
import { DRIVE_TOKEN_COOKIE } from "../model/consts";
import GoogleDrive from "../model/googleDrive";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../app/isLoadingSlice";

function Images() {
  const [{ [DRIVE_TOKEN_COOKIE]: driveToken }] = useCookies([DRIVE_TOKEN_COOKIE]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchFolder() {
      const drive = new GoogleDrive(driveToken);
      const { data: { files } } = await drive.listFiles("mimeType = 'application/vnd.google-apps.folder' and name = 'hideincloud-backup'");
      const { data: { files: db } } = await drive.listFiles(`'${files[0].id}' in parents and name = 'master.db'`);
      const res = await drive.getFile(db[0].id);
      localStorage.setItem('master.db', await blobToBase64(res.data));

      const SQL = await window.initSqlJs({
        locateFile: file => `${process.env.PUBLIC_URL}/${file}`
      });
      let base64db = localStorage.getItem('master.db');
      base64db = base64db.substr(base64db.indexOf(',') + 1);
      const database = new SQL.Database(base64ToUint8Array(base64db));
      const results = database.exec('SELECT * FROM keys;');
      console.log(results);
    }

    dispatch(startLoading());
    fetchFolder().finally(() => dispatch(stopLoading()));
  }, [driveToken, dispatch]);

  return (
    <>
      <List>
      </List>
    </>
  );
}

export default Images;
