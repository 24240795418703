import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Login from './Login';
import Dashboard from './Dashboard';
import { useCookies } from 'react-cookie';
import { DRIVE_TOKEN_COOKIE, USER_DETAIL_COOKIE } from '../model/consts';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectIsLoading } from '../app/isLoadingSlice';
import { Redirect, Route, Switch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

const availablePaths = [
  'image',
  'video',
  'audio',
  'document',
  'other',
].reduce(
  (memo, val) => memo.concat([`/${val}s`, `/${val}s/*`]),
  [],
);

export default function Main() {
  const isLoading = useSelector(selectIsLoading);
  const [{[DRIVE_TOKEN_COOKIE]: driveToken}, setCookie] = useCookies([DRIVE_TOKEN_COOKIE, USER_DETAIL_COOKIE]);
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Switch>
        <Route exact path="/login">
          { driveToken ? (
            <Redirect to="/" />
          ) : (
            <Login
              onLogin={
                (response) => {
                  const { accessToken, tokenObj: { expires_at: expiry } } = response;
                  const tokenOptions = { expires: new Date(expiry) };
                  setCookie(DRIVE_TOKEN_COOKIE, accessToken, tokenOptions);

                  const basicProfile = response.getBasicProfile();
                  const [ name, email, imageUrl ] = [basicProfile.getName(), basicProfile.getEmail(), basicProfile.getImageUrl()];
                  setCookie(USER_DETAIL_COOKIE, { name, email, imageUrl }, tokenOptions);
                }
              }
            />
          )}
        </Route>
        <Route exact path={availablePaths.concat(['/', '/login'])}>
          { driveToken ? <Dashboard /> : <Redirect to="/login" /> }
        </Route>
        <Route>
          404
        </Route>
      </Switch>
    </>
  );
}
